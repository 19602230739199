<template>
  <div id="classes">
    <div class="right">
      <div class="title">
        <div class="left_title">
          <div class="img">
            <img src="" alt="" />
          </div>
          <b>课程数据统计</b>
        </div>
        <div class="right_title">导出数据</div>
      </div>
      <div class="right_info">
        <div class="echart">
          <div class="bing" style="width: 324px; height: 324px"></div>
          <div class="zhe" style="width: 900px; height: 324px"></div>
        </div>
        <table>
          <tr>
            <th>序号</th>
            <th>学生姓名</th>
            <th>学生学号</th>
            <th>是否签到</th>
            <th>是否提问</th>
            <th>操作</th>
          </tr>
          <tr v-for="(i, v) in classdata" :key="v">
            <td>{{ v + 1 }}</td>
            <td>{{ i.user.name }}</td>
            <td>{{ i.user.stu_number }}</td>
            <td>{{ i.qiandao == 0 ? "否" : "是" }}</td>
            <td>{{ i.tiwen == 0 ? "否" : "是" }}</td>
            <td class="infos">
              <router-link
                :to="{
                  path: '/main/record/stuanswer',
                  query: { uid: i.uid, csid: csid },
                }"
                >答案详情</router-link
              >
              <router-link to="/main/record/qstu">问卷详情</router-link>
              <router-link to="/main/record/student">学生详情</router-link>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import * as util from "@/unit/network";
import * as echarts from "echarts";

export default {
  data() {
    return {
      classdata: "",
      bingData: [
        { value: 0, name: "已签到" },
        { value: 0, name: "未签到" },
      ],
      csid: this.$route.query.csid,
    };
  },
  mounted() {
    this.classInfo();
    this.getzxt();
    this.zhe();
  },
  methods: {
    classInfo() {
      util.post(
        "/data_class",
        { class_id: this.$route.query.id, csid: this.$route.query.csid },
        (res) => {
          console.log(res);
          console.log(this.bingData[0].value);
          if (res.code == 1) {
            this.classdata = res.data.data;
            this.bingData[0].value = res.data.count;
            this.bingData[1].value = res.data.no_sign_in;
            this.bing();
          }
        }
      );
    },
    getzxt() {
      util.post("/data_zxt", { csid: this.$route.query.csid }, (res) => {
        console.log(res);
      });
    },
    bing() {
      // console.log(echarts);
      let myBing = echarts.init(document.querySelector(".bing"));
      let option1 = {
        title: {
          text: "签到统计",
          left: "left",
          textStyle: {
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}",
        },
        series: [
          {
            name: "签到统计",
            type: "pie",
            radius: "80%",
            data: this.bingData,
            label: {
              normal: {
                show: true,
                position: "inner",
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(145, 164, 210, 0.4)",
              },
            },
          },
        ],
        color: ["#0062ff", "#05c159"],
      };
      myBing.setOption(option1);
    },
    zhe() {
      let myZhe = echarts.init(document.querySelector(".zhe"));
      let option2 = {
        title: {
          text: "练习正确率统计",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        // legend: {
        //     data: ['正确率']
        // },
        // 下载
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["1", "2", "3", "4", "5", "6", "7", ""],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "正确率",
            type: "line",
            stack: "总量",
            // areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: [27, 48, 69, 30, 80],
          },
        ],
      };
      myZhe.setOption(option2);
    },
  },
};
</script>
<style lang="less" scoped>
#classes {
  width: 100%;
  height: 100%;
  display: flex;

  .right {
    // display: flex;
    width: 100%;
    .title {
      height: 72px;
      padding: 19px 34px 19px 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cdcdcd;
      .left_title {
        display: flex;
        align-items: center;
        color: #131313;
        font-weight: 500;
        .img {
          width: 26px;
          height: 27px;
          margin-right: 9px;
        }
      }
      .right_title {
        color: #fff;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #1c5eff;
        border-radius: 5px;
      }
    }
    .right_info {
      height: calc(100% - 72px);
      background: #fff;
      overflow: hidden;
      .select {
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 115px;
        /deep/.el-select {
          width: 176/96in;
        }
        /deep/.el-input__inner {
          height: 27/96in;
        }
        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      .echart {
        width: 13.6rem;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 22px;
        .bing {
          margin-left: 7px;
        }
        .zhe {
          padding-left: 36px;
          border-left: 1px solid #bebec0;
          margin-right: 52px;
        }
      }
      table {
        width: 1360px;
        margin-top: 33px;
        margin-left: 65px;
        border-collapse: collapse;
        border: 1px solid #bebec0;
        font-size: 16px;
        th {
          font-weight: 400;
          background-color: #e9ebee;
          height: 52px;
        }
        td,
        th {
          border-collapse: collapse;
          border: 1px solid #bebec0;
        }
        td {
          height: 80px;
          text-align: center;
          &.infos {
            display: flex;
            align-items: center;
            justify-content: space-around;
            border: 1px;
          }
        }
      }
    }
  }
}
.active {
  background-color: #1c5eff;
}
</style>